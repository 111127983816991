import React from 'react';
import Slider from 'react-slick';
import './FasciaCardImg.scss';
import '../../css/plugins/slick-custom.scss';
import Title from '../../molecules/title/title';
import { useWithMobileResizeListener } from '../../utils/hooks/useWithMobileResizeListener';
import { Wrapper } from '../../utils/components';
import { PrevArrow, NextArrow } from '../../molecules/arrows/arrows';
import { getCommonOrganismData } from '../../utils/functions';
import AnchorHooking from '../../molecules/anchor-hooking/AnchorHooking';
import classNames from 'classnames';
import withPreview from '../../utils/with-preview';
import CardImg from './CardImg';

const FasciaCardImg = ({ data, considerMenuSpace }) => {
  const fasciaCardImg = data;

  const {
    htmlTagWrapper,
    backgroundColor,
    anchorLabel,
    title,
    titleHeadingTag,
    titleIconId,
    marginTop,
    titleIconThickness,
  } = getCommonOrganismData(data);

  const isMobile = useWithMobileResizeListener(768);
  const disableSliderOver = fasciaCardImg?.disableSliderOver?.value;
  const disableSliderUnder = fasciaCardImg?.disableSliderUnder?.value;
  const disableArrow = fasciaCardImg?.disableArrow?.value;
  const disableDots = fasciaCardImg?.disableDots?.value;

  const cardsNumber = fasciaCardImg?.cardsNumber?.value;
  const cards = fasciaCardImg?.cards;

  const settings = {
    infinite: false, // If is set to true, if elements < sideToShow it generetes clone tile
    dots: !disableDots,
    speed: 500,
    arrows: !disableArrow,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 4,
    slidesToScroll: 1,
    slide: '',
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: '10px 0' }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <div className='slick-dots__custom-dot'></div>,
  };

  const renderSlider = () => {
    return (
      <Slider {...settings} className='fascia-card-img__carousel'>
        {cards.map((card, i) => (
          <CardImg key={i} data={card.content} />
        ))}
      </Slider>
    );
  };

  const renderNormalCards = () => {
    return (
      <div
        className='fascia-card-img__carousel fascia-card-img__carousel--disabled'
        data-cardsNumber={cardsNumber}
      >
        {cards.map((card, i) => (
          <CardImg key={i} data={card.content} />
        ))}
      </div>
    );
  };

  const renderMobile = () => {
    if (disableSliderUnder) {
      return renderNormalCards();
    }
    return renderSlider();
  };

  const renderDesktop = () => {
    if (disableSliderOver) {
      return renderNormalCards();
    }
    return renderSlider();
  };

  return (
    <Wrapper
      tag={htmlTagWrapper}
      data-module-code='C-77'
      data-module-name='fascia-card-img'
      className={classNames(`fascia-card-img ${marginTop}`, {
        'fascia-card-img--colored-background':
          backgroundColor !== 'transparent',
      })}
      style={{ '--bg': `var(--${backgroundColor})` }}
    >
      <AnchorHooking id={anchorLabel} belowMenu={considerMenuSpace} />
      <div className='fascia-card-img__container wrapper'>
        {(title || titleIconId) && (
          <Title
            tag={titleHeadingTag}
            titleIconId={titleIconId}
            titleIconThickness={titleIconThickness}
          >
            {title}
          </Title>
        )}

        {isMobile ? renderMobile() : renderDesktop()}
      </div>
    </Wrapper>
  );
};

export default withPreview(FasciaCardImg);
