import React from 'react';
import { Link } from '../../organisms/link';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const CardImg = (props) => {
  let card = props.data;
  let image = card?.cardImg?.node?.gatsbyImageData;
  return (
    <Link
      to={card.cardLink.value}
      data-submodule-name='card-img'
      className='card-img'
    >
      {image && (
        <GatsbyImage
          className='card-img__image'
          image={getImage(image)}
          alt='Banner'
        />
      )}
    </Link>
  );
};

export default CardImg;
